import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import H2 from '@rotaready/frecl/build/H2';
import Text from '@rotaready/frecl/build/Text';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import RelativeWrapper from '../layout/RelativeWrapper';
import ContentWrapper from '../layout/ContentWrapper';

import QuotationMark from '../frills/QuotationMark';

import { ViewportBreakpoints } from '../cssConstants';

const BenefitIllustration = styled.div`
  margin: 60px 0;
  text-align: center;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 0 100%;
    width: 50%;
    margin: 0;
  }
`;

const BenefitBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 0 50%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const BenefitText = styled(Text)`
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
`;

const BenefitTestimonial = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 0 50%;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const InnerContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 510px;

    ${BenefitIllustration} {
      order: ${props => props.mirrorArrange ? 0 : 1};
    }

    ${BenefitBody}, ${BenefitTestimonial} {
      order: ${props => props.mirrorArrange ? 1 : 0};
    }
  }
`;

const Quote = styled.div`
  margin-bottom: 20px;
`;

const QuoteAttribution = styled.div`
  margin-bottom: 20px;

  p {
    color: ${({ theme: { colors } }) => colors.brand160};
    font-weight: 600;
  }
`;

const QuoteImage = styled.div``;

const Benefit = ({
  backgroundColor,
  mirrorArrange,
  title,
  description,
  illustrationImage,
  illustrationAltText,
  quote,
  quoteAttribution,
  quoteImage,
}) => (
  <RelativeWrapper backgroundColor={backgroundColor}>
    <ResponsiveContainer>
      <ContentWrapper>
        <InnerContainer mirrorArrange={mirrorArrange}>
          <BenefitBody>
            <H2 uistyle="brand160" text={title} />
            <BenefitText text={description} />
          </BenefitBody>
          <BenefitIllustration>
            <GatsbyImage
              image={illustrationImage}
              alt={illustrationAltText}
            />
          </BenefitIllustration>
          <BenefitTestimonial>
            <QuotationMark width={40} />
            <Quote>
              <Text text={quote} size="lg" />
            </Quote>
            <QuoteAttribution>
              <Text text={quoteAttribution} />
            </QuoteAttribution>
            <QuoteImage>
              <GatsbyImage
                image={quoteImage}
                alt="Quote logo"
              />
            </QuoteImage>
          </BenefitTestimonial>
        </InnerContainer>
      </ContentWrapper>
    </ResponsiveContainer>
  </RelativeWrapper>
);

Benefit.propTypes = {
  backgroundColor: PropTypes.string,
  mirrorArrange: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  illustrationImage: PropTypes.shape({}).isRequired,
  illustrationAltText: PropTypes.string,
  quote: PropTypes.string.isRequired,
  quoteAttribution: PropTypes.string.isRequired,
  quoteImage: PropTypes.shape({}).isRequired,
};

Benefit.defaultProps = {
  backgroundColor: 'white',
  mirrorArrange: false,
  illustrationAltText: '',
};

export default Benefit;
